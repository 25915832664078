<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-card>
      <v-toolbar
        dense
        color="transparent"
      >
        <v-btn
          icon
          @click="$router.push({name: 'goods'})"
        >
          <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="viewRow"
          v-permission="['80003']"
          color="primary"
          class="mx-2"
          small
          @click="editClick"
        >
          编辑
        </v-btn>
        <v-btn
          v-else
          small
          color="primary"
          class="mx-2"
          @click="formSubmit"
        >
          保存
        </v-btn>
        <v-btn
          outlined
          small
          class="mx-2"
          @click="$router.push({name: 'goods'})"
        >
          取消
        </v-btn>
      </v-toolbar>
      <v-card-text
        class="overflow-y-auto"
        style="max-height: 85vh"
      >
        <v-form
          ref="goodsFormRef"
          :disabled="viewRow"
        >
          <v-card-subtitle
            class="d-flex justify-center mt-4"
          >
            --- 基本信息 ---
          </v-card-subtitle>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="row.name"
                :counter="20"
                :rules="[rules.required, rules.counter]"
                label="商品名称"
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="row.sku"
                label="SKU"
                outlined
                readonly
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="row.length"
                :counter="20"
                :rules="[rules.counter]"
                label="长度"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="row.width"
                :counter="20"
                :rules="[rules.counter]"
                label="宽度"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="row.height"
                :counter="20"
                :rules="[rules.counter]"
                label="高度"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="row.weight"
                :counter="20"
                :rules="[rules.counter]"
                label="重量"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="row.cost_price"
                type="number"
                :rules="[rules.required, rules.amount]"
                label="成本价"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="row.sale_price"
                type="number"
                :rules="[rules.required, rules.amount]"
                label="销售价"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="row.category_name"
                :rules="[rules.required]"
                label="所属组"
                readonly
                outlined
                dense
                @click="categoryInputClick"
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
              md="4"
            >
              <v-checkbox
                v-model="row.track_inventory"
                dense
                label="统一库存管理"
              ></v-checkbox>
            </v-col>
            <v-col
              v-if="row.track_inventory"
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="row.inventory"
                type="number"
                :rules="[rules.required, rules.integer]"
                label="库存"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
              md="4"
            >
              <v-checkbox
                v-model="row.is_publish"
                label="发布产品"
                dense
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-card-subtitle
            class="d-flex justify-center"
          >
            --- 商品描述 ---
          </v-card-subtitle>
          <v-row>
            <v-col
              cols="12"
            >
              <froala
                ref="editor"
                v-model="row.description"
                tag="textarea"
                :config="config"
              ></froala>
            </v-col>
          </v-row>
          <v-card-subtitle
            class="d-flex justify-center mt-4"
          >
            --- 商品图片 ---
          </v-card-subtitle>
          <v-row>
            <v-col
              cols="12"
            >
              <v-file-input
                multiple
                label="点击上传"
                accept="image/*"
                prepend-icon=""
                :append-icon="icons.mdiFolderImage"
                outlined
                dense
                @change="selectFile"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-card
            hover
            outlined
            min-height="100"
            class="pa-4"
          >
            <v-card-text
              v-if="!images.length"
              class="d-flex justify-center"
            >
              尚未添加任何图片信息
            </v-card-text>
            <v-row
              v-for="(item, index) in images"
              :key="index"
              class="align-center"
            >
              <v-col cols="4">
                <v-img
                  :src="item.image"
                  max-height="50"
                  max-width="50"
                ></v-img>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  v-model="item.is_cover"
                  label="设为封面"
                  dense
                  @change="setCoverImage(index)"
                ></v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-btn
                  v-if="!viewRow"
                  icon
                  @click="deleteImage(index)"
                >
                  <v-icon>{{ icons.mdiDelete }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <v-card-subtitle
            class="d-flex justify-center mt-4"
          >
            --- 商品属性 ---
          </v-card-subtitle>
          <v-card
            hover
            outlined
            min-height="100"
            class="pa-4"
          >
            <v-card-text
              v-if="!row.options.length"
              class="d-flex justify-center"
            >
              尚未添加任何属性信息
            </v-card-text>
            <v-simple-table v-else>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      属性名
                    </th>
                    <th class="text-left">
                      显示名
                    </th>
                    <th class="text-left">
                      属性值
                    </th>
                    <th class="text-left">
                      操作
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in row.options"
                    :key="item.id"
                  >
                    <td>{{ item.option_name }}</td>
                    <td>{{ item.display_name }}</td>
                    <td>
                      <v-chip
                        v-for="v in item.values"
                        :key="v.id"
                        x-small
                      >
                        {{ v.label }}
                      </v-chip>
                    </td>
                    <td>
                      <v-btn
                        v-if="!viewRow"
                        icon
                        @click="deleteOption(index)"
                      >
                        <v-icon>{{ icons.mdiDelete }}</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-card-text
              class="d-flex justify-center"
            >
              <v-btn
                v-if="!viewRow"
                text
                color="primary"
                @click="addGoodsOptionClick"
              >
                <v-icon>{{ icons.mdiPlus }}</v-icon>
                添加属性
              </v-btn>
            </v-card-text>
          </v-card>
          <v-card-subtitle
            class="d-flex justify-center mt-4"
          >
            --- 商品变体 ---
          </v-card-subtitle>
          <v-card
            hover
            outlined
            min-height="100"
            class="pa-4"
          >
            <v-card-text
              v-if="!row.variants.length"
              class="d-flex justify-center"
            >
              添加属性后将创建变体。
            </v-card-text>
            <v-simple-table
              v-else
              class="overflow-x-auto"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      class="text-left"
                      style="min-width: 80px"
                    >
                      可选中
                    </th>
                    <th
                      class="text-left"
                      style="min-width: 80px"
                    >
                      图片
                    </th>
                    <th
                      class="text-left"
                      style="min-width: 100px"
                    >
                      变体
                    </th>
                    <th
                      class="text-left"
                      style="min-width: 150px"
                    >
                      SKU
                    </th>
                    <th
                      class="text-left"
                      style="min-width: 100px"
                    >
                      成本价
                    </th>
                    <th
                      class="text-left"
                      style="min-width: 100px"
                    >
                      销售价
                    </th>
                    <th
                      class="text-left"
                      style="min-width: 100px"
                    >
                      {{ row.track_inventory ? '库存扣除': '库存' }}
                    </th>
                    <th
                      class="text-left"
                      style="min-width: 100px"
                    >
                      长度
                    </th>
                    <th
                      class="text-left"
                      style="min-width: 100px"
                    >
                      宽度
                    </th>
                    <th
                      class="text-left"
                      style="min-width: 100px"
                    >
                      高度
                    </th>
                    <th
                      class="text-left"
                      style="min-width: 100px"
                    >
                      重量
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in row.variants"
                    :key="index"
                  >
                    <td
                      class="text-left"
                    >
                      <v-simple-checkbox
                        v-model="item.is_selected"
                        :disabled="viewRow"
                      ></v-simple-checkbox>
                    </td>
                    <td
                      class="text-left"
                    >
                      <v-img
                        lazy-src="https://dummyimage.com/32x32/f5f5f5/9e9e9e.png"
                        :src="item.cover_image || 'https://dummyimage.com/32x32/f5f5f5/9e9e9e.png'"
                        max-height="50"
                        max-width="50"
                        min-width="50"
                        min-height="50"
                        style="border: 1px"
                      >
                        <input
                          type="file"
                          accept="image/*"
                          class="my-0 py-0"
                          title="点击上传"
                          style="opacity: 0; height: 100%"
                          :disabled="viewRow"
                          @change="selectVariantImage(item, $event)"
                        >
                      </v-img>
                    </td>
                    <td
                      class="text-left"
                    >
                      <v-chip
                        v-for="o in item.option_values"
                        :key="o.id"
                        x-small
                      >
                        {{ o.label }}
                      </v-chip>
                    </td>
                    <td
                      class="text-left"
                    >
                      {{ item.sku }}
                    </td>
                    <td
                      class="text-left"
                    >
                      <v-text-field
                        v-model="item.cost_price"
                        :rules="[rules.required, rules.amount]"
                        hide-details="auto"
                        dense
                      ></v-text-field>
                    </td>
                    <td
                      class="text-left"
                    >
                      <v-text-field
                        v-model="item.sale_price"
                        :rules="[rules.required, rules.amount]"
                        hide-details="auto"
                        dense
                      ></v-text-field>
                    </td>
                    <td
                      class="text-left"
                    >
                      <v-text-field
                        v-model="item.inventory"
                        :rules="[rules.required, rules.integer]"
                        hide-details="auto"
                        dense
                      ></v-text-field>
                    </td>
                    <td
                      class="text-left"
                    >
                      <v-text-field
                        v-model="item.length"
                        :rules="[rules.counter]"
                        hide-details="auto"
                        dense
                      ></v-text-field>
                    </td>
                    <td
                      class="text-left"
                    >
                      <v-text-field
                        v-model="item.width"
                        :rules="[rules.counter]"
                        hide-details="auto"
                        dense
                      ></v-text-field>
                    </td>
                    <td
                      class="text-left"
                    >
                      <v-text-field
                        v-model="item.height"
                        :rules="[rules.counter]"
                        hide-details="auto"
                        dense
                      ></v-text-field>
                    </td>
                    <td
                      class="text-left"
                    >
                      <v-text-field
                        v-model="item.weight"
                        :rules="[rules.counter]"
                        hide-details="auto"
                        dense
                      ></v-text-field>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-form>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="categoriesDialog"
      max-width="500px"
    >
      <v-card :loading="categoriesLoading">
        <v-toolbar
          flat
          color="transparent"
        >
          <v-text-field
            v-model="categoriesOption.search"
            rounded
            dense
            outlined
            hide-details
            label="搜索"
            placeholder="按商户组名称搜索"
            :append-icon="icons.mdiMagnify"
            class="flex-grow-0"
            @click:append="getCategories"
          />
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-list
          flat
          subheader
        >
          <v-subheader class="justify-center">
            商品分类
          </v-subheader>
          <v-divider></v-divider>
          <v-list-item-group
            color="primary"
            class="d-flex justify-start flex-wrap mx-4"
          >
            <v-list-item
              v-for="item in categories"
              :key="item.id"
              class="flex-basis-33"
              @click="clickCategory(item)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-card-actions class="justify-center">
          <v-pagination
            v-model="categoriesOption.page"
            circle
            :length="Math.ceil(categoriesTotal / categoriesOption.pageSize)"
            total-visible="5"
            @input="getCategories"
          ></v-pagination>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="optionsDialog"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-toolbar
          flat
          color="transparent"
        >
          <v-text-field
            v-model="goodsOptionOptions.search"
            rounded
            dense
            outlined
            hide-details
            label="搜索"
            placeholder="按属性名、显示名搜索"
            :append-icon="icons.mdiMagnify"
            class="flex-grow-0"
            @click:append="getGoodsOptions"
          />
        </v-toolbar>
        <v-data-table
          :headers="optionsHeaders"
          :items="goodsOptions"
          :items-per-page.sync="goodsOptionOptions.pageSize"
          :page.sync="goodsOptionOptions.page"
          :server-items-length="goodsOptionsTotal"
          :loading="optionsLoading"
          :value="row.options"
          disable-sort
          show-select
          checkbox-color="primary"
          :footer-props="{itemsPerPageOptions: [10, 15, 20, 50, 100]}"
          @update:items-per-page="getGoodsOptions"
          @update:page="getGoodsOptions"
          @input="selectOption"
        >
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="cancelSelectOptions"
          >
            取消
          </v-btn>
          <v-btn
            color="success"
            :loading="confirmSelectOptionLoading"
            @click="confirmSelectOptions"
          >
            确认
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// eslint-disable-next-line import/extensions,import/no-extraneous-dependencies
import 'froala-editor/js/plugins/font_family.min.js'
import 'froala-editor/js/plugins/font_size.min.js'
import 'froala-editor/js/plugins/fullscreen.min.js'
import 'froala-editor/js/plugins/align.min.js'
import 'froala-editor/js/plugins/code_view.min.js'
import 'froala-editor/js/plugins/colors.min.js'
import 'froala-editor/js/plugins/table.min.js'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/lists.min.js'
import 'froala-editor/js/plugins/markdown.min.js'
import 'froala-editor/js/plugins/line_height.min.js'
import 'froala-editor/js/plugins/inline_class.min.js'
import 'froala-editor/js/plugins/image.min.js'
// import 'froala-editor/js/plugins/video.min.js'

import {
  mdiFolderImage,
  mdiDelete,
  mdiArrowLeft,
  mdiMagnify,
  mdiPlus,
} from '@mdi/js'

import {
  getCategories,
  getOptions,
  getOption,
  addGood,
  updateGood,
  getGood,
} from '@/api/goods'
import { uploadImage } from '@/api/upload'

export default {
  name: 'Detail',
  data() {
    const self = this
    return {
      rowId: null,
      tabs: 0,
      editor: null,
      viewRow: false,
      row: {
        name: null,
        sku: Date.now().toString().slice(0, -3),
        length: null,
        width: null,
        height: null,
        weight: null,
        inventory: 0,
        track_inventory: false,
        is_publish: false,
        category: null,
        category_name: null,
        cost_price: null,
        sale_price: null,
        description: null,
        options: [],
        variants: [],
      },
      rules: {
        required: value => (!!value || value === 0) || '必填项.',
        counter: value => (!value || value.length <= 20) || '最大20个字符',
        integer: value => (!value || /^\d*$/.test(value)) || '必须为正整数',
        amount: value => (!value || /^\d+(\.\d{1,2})?$/.test(value)) || '价格不合法',
      },
      config: {
        placeholderText: '在这里编辑你的产品描述信息',
        language: 'zh_cn',
        fontFamilySelection: true,
        imageUploadURL: `${process.env.VUE_APP_BASE_API}/api/v1/backend/images`,
        imageUploadParam: 'image',
        requestHeaders: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'X-FORWARDED-PROTO': 'https',
        },
        events: {
          initialized() {
            self.editor = this
            if (self.viewRow) {
              this.edit.off()
            } else {
              this.edit.on()
            }
          },
          'image.uploaded': function (response) {
            const { code, msg } = JSON.parse(response)
            if (code !== 200) {
              self.$toast.error(msg)
              return false
            }
            return true
          },
        },
      },
      images: [],
      selectGoodsOptions: [],
      icons: {
        mdiFolderImage,
        mdiDelete,
        mdiArrowLeft,
        mdiMagnify,
        mdiPlus,
      },
      categoriesDialog: false,
      categoriesLoading: true,
      categoriesTotal: 0,
      categories: [],
      categoriesOption: {
        search: '',
        page: 1,
        pageSize: 20,
      },
      goodsOptions: [],
      optionsDialog: false,
      optionsLoading: true,
      goodsOptionsTotal: 0,
      optionsHeaders: [
        { text: '属性名称', value: 'option_name' },
        { text: '显示名称', value: 'display_name' },
        { text: '使用商品数量', value: 'goods_count' },
      ],
      goodsOptionOptions: {
        search: '',
        page: 1,
        pageSize: 10,
      },
      confirmSelectOptionLoading: false,
    }
  },
  watch: {
    viewRow(value) {
      if (!this.editor) return
      if (value) {
        this.editor.edit.off()
      } else {
        this.editor.edit.on()
      }
    },
  },
  created() {
    const { id } = this.$route.query
    if (id) {
      this.viewRow = true
      this.rowId = id
      this.getGoodsDetail()
    }
  },
  methods: {
    async getGoodsDetail() {
      const { data } = await getGood(this.rowId)
      this.row = data
      this.images = data.images
    },
    selectFile(files) {
      files.forEach(async file => {
        const formData = new FormData()
        formData.append('image', file)
        const { data } = await uploadImage(formData)
        this.images = [...this.images, {
          image: data.image,
          is_cover: false,
        }]
      })
    },
    deleteImage(index) {
      this.images.splice(index, 1)
    },
    deleteOption(index) {
      this.row.options.splice(index, 1)
      this.genVariants()
    },
    editClick() {
      this.viewRow = false
    },
    setCoverImage(index) {
      const item = this.images[index]
      if (item.is_cover) {
        this.images.forEach((el, i) => {
          if (i !== index) el.is_cover = false
        })
      }
    },
    categoryInputClick() {
      this.categoriesOption.page = 1
      this.categoriesOption.search = ''
      this.categoriesDialog = true
      this.categoriesLoading = true
      this.getCategories().then(() => {
        this.categoriesLoading = false
      })
    },
    async getCategories() {
      const { data } = await getCategories(this.categoriesOption)
      this.categories = data.results
      this.categoriesTotal = data.count
    },
    clickCategory(item) {
      this.row.category = item.id
      this.row.category_name = item.name
      this.categoriesDialog = false
    },
    addGoodsOptionClick() {
      this.goodsOptionOptions.page = 1
      this.goodsOptionOptions.search = ''
      this.optionsDialog = true
      this.getGoodsOptions()
    },
    async getGoodsOptions() {
      this.optionsLoading = true
      const { data } = await getOptions(this.goodsOptionOptions)
      this.goodsOptions = data.results
      this.goodsOptionsTotal = data.count
      this.optionsLoading = false
    },
    selectOption(options) {
      this.selectGoodsOptions = options
    },
    confirmSelectOptions() {
      this.row.options = []
      this.confirmSelectOptionLoading = true
      const promiseList = []
      this.selectGoodsOptions.forEach(item => {
        promiseList.push(getOption(item.id))
      })
      Promise.all(promiseList).then(results => {
        results.forEach(res => {
          this.row.options.push(res.data)
        })
        this.genVariants()
        this.confirmSelectOptionLoading = false
        this.optionsDialog = false
      })
    },
    cancelSelectOptions() {
      this.optionsDialog = false
    },
    getDimComb(doubleList) {
      if (doubleList.length === 0) return []
      const result = []
      const $back = (doubleList, index, curList) => {
        if (index >= doubleList.length) {
          result.push([...curList])
        } else {
          doubleList[index].forEach(item => {
            curList.push(item)
            $back(doubleList, index + 1, curList)
            curList.pop()
          })
        }
      }
      $back(doubleList, 0, [])
      return result
    },
    genVariants() {
      const skuMap = this.row.variants.reduce((pre, cur, index) => {
        const key = cur.option_values.map(item => item.id).join('-')
        pre[key] = index
        return pre
      }, {})
      const options = this.row.options.map(item => item.values)
      const variantList = this.getDimComb(options)
      const newVariants = []
      variantList.forEach(item => {
        const key = item.map(item => item.id).join('-')
        const i = skuMap[key]
        if (i) {
          newVariants.push(this.row.variants[i])
        } else {
          newVariants.push({
            sku: `${this.row.sku}-${key}`,
            length: this.row.length,
            width: this.row.width,
            height: this.row.height,
            weight: this.row.weight,
            inventory: '0',
            cost_price: this.row.cost_price,
            sale_price: this.row.sale_price,
            cover_image: null,
            is_selected: true,
            option_values: item,
          })
        }
      })
      this.row.variants = newVariants
    },
    async selectVariantImage(item, e) {
      const file = e.target.files[0]
      if (file) {
        const formData = new FormData()
        formData.append('image', file)
        const { data } = await uploadImage(formData)
        item.cover_image = data.image
      }
    },
    async formSubmit() {
      if (!this.$refs.goodsFormRef.validate()) return
      this.row.images = this.images
      if (this.rowId) {
        await updateGood(this.rowId, this.row)
        this.$toast.success('更新成功')
        await this.$router.push({ name: 'goods' })
      } else {
        await addGood(this.row)
        this.$toast.success('创建成功')
        await this.$router.push({ name: 'goods' })
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.flex-basis-33
  flex-basis: 33% !important
</style>
